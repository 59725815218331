import React from "react";
import GoogleMapReact from 'google-map-react';
import { FaUniversalAccess } from "react-icons/fa";

const lat = 45.00540327208567;
const lng = -93.0053855501533;

const center = {
    lat: lat,
    lng: lng,
};

const googleMapsApiKey = "AIzaSyAvO6CKfcyT5y6A0DGvsQP3uZxfzowIL_k"

const MapMarker = () => <FaUniversalAccess className='fill-icon-blue h-8 text-6xl' />;

export default function GoogleLocationMap() {
    const defaultProps = {
        center: center,
        zoom: 14
    };

    return (
        // Important! Always set the container height explicitly
                    <div className='flex mx-auto border md:mt-4 mb-12' style={{ height: '50vh', width: '66%' }}>

            <GoogleMapReact
                bootstrapURLKeys={{ key: googleMapsApiKey }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
            >
                <MapMarker lat={lat} lng={lng} />
            </GoogleMapReact>
        </div>
    );
}