import * as React from "react";
import { navigate } from "gatsby-link";
import Layout from "../../components/Layout";
import HeaderBanner1 from "../../components/HeaderBanner1";
import GoogleLocationMap from "../../components/GoogleLocationMap";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })

      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error));
  };

  render() {
    return (
      <Layout className='font-sans'>
        {" "}
        <div className='py-10 bg-white md:py-16'>
          <div className='px-10 mx-auto md:w-8/12'>
            <div className=' mx-auto mb-10 md:mb-16'>
              <p className='text-xs font-bold text-blue-500 uppercase'>
                Contact Us
              </p>
              <h2 className='mt-1 text-2xl font-bold text-left text-gray lg:text-3xl md:mt-2'>
                Need to ask us a question?
              </h2>
              <p className='mx-auto mt-4 text-left text-gray-500 md:text-lg md:mt-6'>
                Fill out the form below and get back to you within 24-48 business hours.
              </p>
            </div>

            <form
              className='grid gap-4 mx-auto sm:grid-cols-2'
              name='contact'
              method='post'
              action='/contact/thanks/'
              data-netlify='true'
              data-netlify-honeypot='bot-field'
              onSubmit={this.handleSubmit}
            >
              {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
              <input type='hidden' name='form-name' value='contact' />
              <div hidden>
                <label>
                  Don’t fill this out:{" "}
                  <input name='bot-field' onChange={this.handleChange} />
                </label>
              </div>
              <div className='field'>
                <label
                  htmlFor={"firstName"}
                  className='inline-block mb-2 text-sm font-medium text-gray-500 sm:text-base'
                >
                  First name
                </label>
                <input
                  className='w-full px-3 py-2 text-gray transition duration-100 border rounded-md outline-none bg-gray-50 focus:ring ring-blue-300'
                  type={"text"}
                  name={"firstName"}
                  onChange={this.handleChange}
                  id={"firstName"}
                  required={true}
                />
              </div>

              <div className='field'>
                <label
                  className='inline-block mb-2 text-sm font-medium text-gray-500 sm:text-base'
                  htmlFor={"lastName"}
                >
                  Last Name
                </label>
                <input
                  className='w-full px-3 py-2 text-gray transition duration-100 border rounded-md outline-none bg-gray-50 focus:ring ring-blue-300'
                  type={"text"}
                  name={"lastName"}
                  onChange={this.handleChange}
                  id={"lastName"}
                  required={true}
                />
              </div>

              <div className='sm:col-span-2'>
                <label
                  htmlFor={"company"}
                  className='inline-block mb-2 text-sm font-medium text-gray-500 sm:text-base'
                >
                  Company
                </label>
                <input
                  className='w-full px-3 py-2 text-gray transition duration-100 border rounded-md outline-none bg-gray-50 focus:ring ring-blue-300'
                  type={"text"}
                  name={"company"}
                  onChange={this.handleChange}
                  id={"company"}
                  required={true}
                />
              </div>

              <div className='sm:col-span-2'>
                <label
                  htmlFor={"email"}
                  className='inline-block mb-2 text-sm font-medium text-gray-500 sm:text-base'
                >
                  Email
                </label>
                <input
                  className='w-full px-3 py-2 text-gray transition duration-100 border rounded-md outline-none bg-gray-50 focus:ring ring-blue-300'
                  type={"email"}
                  name={"email"}
                  onChange={this.handleChange}
                  id={"email"}
                  required={true}
                />
              </div>

              <div className='sm:col-span-2'>
                <label
                  htmlFor={"phoneNumber"}
                  className='inline-block mb-2 text-sm font-medium text-gray-500 sm:text-base'
                >
                  Phone Number
                </label>
                <input
                  className='w-full px-3 py-2 text-gray transition duration-100 border rounded-md outline-none bg-gray-50 focus:ring ring-blue-300'
                  type={"tel"}
                  name={"phoneNumber"}
                  onChange={this.handleChange}
                  id={"phoneNumber"}
                  required={true}
                />
              </div>

              <div className='sm:col-span-2'>
                <label
                  htmlFor='subject'
                  className='inline-block mb-2 text-sm font-medium text-gray-500 sm:text-base'
                >
                  Subject
                </label>
                <input
                  className='w-full px-3 py-2 text-gray transition duration-100 border rounded-md outline-none bg-gray-50 focus:ring ring-blue-300'
                  type={"text"}
                  name={"subject"}
                  onChange={this.handleChange}
                  id={"subject"}
                  required={true}
                />
              </div>

              <div className='field sm:col-span-2'>
                <label
                  className='label inline-block mb-2 text-sm font-medium text-gray-500 sm:text-base'
                  htmlFor={"message"}
                >
                  Message
                </label>

                <textarea
                  className='textarea w-full px-3 py-2 text-gray transition duration-100 border rounded-md outline-none bg-gray-50 focus:ring ring-blue-300'
                  name={"message"}
                  onChange={this.handleChange}
                  id={"message"}
                  required={true}
                />
              </div>

              <div className='field flex items-center justify-between sm:col-span-2'>
                <button
                  type='submit'
                  className='inline-block px-8 border py-3 text-sm font-semibold text-green text-center transition duration bg-blue rounded-md outline-none hover:bg-blue active:bg-blue ring-blue md:text-base'
                >
                  Send Message
                </button>
              </div>
            </form>
            <p className='max-w-3xl mx-auto mt-5 text-xs text-gray-400'>
              Please allow up to 24-48 hour response during the weekdays.
            </p>
          </div>
        </div>
        <div className='flex flex-row mx-auto px-10 md:px-0 md:w-8/12'>
          <div className='mx-auto mb-10 md:mb-8 pr-4'>
            <p className='text-xs font-bold text-blue-500 uppercase'>
              LOCATION
            </p>
            <h2 className='mt-1 text-base md:text-xl font-semibold text-left text-gray lg:text-3xl md:mt-2'>
              Address: 
              <p className='text-sm md:text-base text-black md:ml-4 mt-2 text-left'>
              2255 7th Ave E, <br />North St Paul<br /> MN 55109</p>
            </h2>
           
            <h2 className='mt-1 text-xl font-semibold text-left text-gray lg:text-3xl md:mt-2'>
              (main)
              <p className='text-sm md:text-base text-black md:ml-4 mt-2 text-left'>
                612.871.0805</p>
            </h2>
            <h2 className='mt-1 text-xl font-semibold text-left text-gray lg:text-3xl md:mt-2'>
              (fax)
              <p className='text-sm md:text-base text-black md:ml-4 mt-2 text-left'>
                612.871.0455 </p>
            </h2>
          </div>
          <GoogleLocationMap />
        </div>
        <HeaderBanner1 />
      </Layout>
    );
  }
}
